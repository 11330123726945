<template>
  <div class="dark container">
    <div class="actions flex-row space-between-center">
      <input v-model="limit" type="text" maxLength="5">
      <b-button size="sm" variant="primary"
        @click="run()"><b>&gt;</b>
      </b-button>
    </div>
    <div class="editor" ref="editorEl"></div>

    <b-modal v-model="showResult" size="xl" hide-footer>
      <details>
        <summary v-if="response">
          Count: {{ response.result.length - 1 }} /
          {{ response.totalCount }}
        </summary>
        <div>{{ selectedText }}</div>
      </details>
      <div class="table-wrapper">
        <table v-if="response">
          <tr v-for="(line,i) in response.result" :key="i"
            :class="i === 0 ? 'header' : null">
            <td v-for="(v, j) in line" :key="i + '-' + j">
              {{ v }}
            </td>
          </tr>
        </table>
      </div>
      <template #modal-header>
        Result:
        <div class="d-flex" style="gap: 0.5rem;">
          <b-button @click="showResult = false">Cerrar</b-button>
          <b-button variant="success"
            @click="saveCSV()">Export to CSV
          </b-button>
        </div>
      </template>
    </b-modal>
    <a ref="link" style="display: hidden"></a>
  </div>
</template>

<script>
import axios from 'axios'
import { basicSetup, EditorView } from "codemirror"
import { sql } from "@codemirror/lang-sql"
const theme = EditorView.baseTheme({
  "&": {
    color: "white",
    backgroundColor: "#034",
    'font-family': 'monospace',
  },
  ".ͼb": {
    color: "orange",
    'font-weight': 'bold',
  },
  '.ͼd': {
    color: 'red',
    'font-weight': 'bold',
  },
  '.cm-content ::selection': {
    'backgroundColor': '#FFd8ff',
    // 'color': '#000',
  }
  // "&.cm-focused .cm-cursor": { borderLeftColor: "#0e9" },
  // "&.cm-focused .cm-selectionBackground, ::selection": {
  //   backgroundColor: "green"
  // },
  // ".cm-gutters": {
  //   backgroundColor: "#045",
  //   color: "white",
  //   border: "none"
  // }
}, { dark: true })

export default {
  data: () => ({
    editor: null,
    showResult: false,
    response: null,
    limit: 100,
  }),
  mounted() {
    axios.get('/user/info').then(resp => {
      if (!resp.data.is_superuser) {
        return this.$route.replace({ path: '/' })
      }
      this.editor = new EditorView({
        doc: "\nSELECT 1\n\n\n\n\n\n\n",
        extensions: [ basicSetup, sql(), theme ],
        parent: this.$refs.editorEl
      })
    })
  },
  computed: {
    selectedText () {
      if (!this.editor) { return '' }
      const { state } = this.editor
      const { from, to } = state.selection.main
      return state.sliceDoc(from, to)
        .replace("\n", ' ')
        .trim()
    }
  },
  methods: {
    run() {
      const selectedText = this.selectedText
      if (!selectedText) {
        alert('No data')
        return
      }

      axios.post('midecc/lectura-masiva/', {
        sql: selectedText,
        limit: this.limit,
      }).then( resp => {
        this.response = resp.data
        this.showResult = true
      }).catch( err => alert(err))
    },
    saveCSV() {
      const csvContent = 'data:text/csv;charset=utf-8,'
        + this.response.map(line => line.join(",")).join("\n")

      const encodedUri = encodeURI(csvContent);
      this.$refs.link.setAttribute("href", encodedUri);
      this.$refs.link.setAttribute("download", 'response.csv');
      this.$refs.link.click()
    }
  }
}
</script>

<style scoped>
.editor { border: 1px solid #CCC; }
.container { padding-top: 30px; padding-bottom: 30px; }
.actions { padding: 0 1rem; background: #222; }
.table-wrapper {
  width: 100%;
  max-height: 70vh;
  overflow: auto;
}
table { margin: 0 auto; }
td { border: 1px solid #CCC; padding: 3px 5px; }
tr.header { background-color: #222; color: white; font-weight: bold; }
input {
  width: 5rem;
  background: transparent;
  border: none;
  color: #888;
  font-weight: bold;
  outline: none;
}
summary {
  color: #CCC; display: block; text-align: right;
  outline: none; cursor: default;
}
details { color: #888; }
details > div { padding: 0 0.5rem; margin-bottom: 1rem; }
</style>
